import React, { Component } from "react";

class AboutPage extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <section className="about-site section-padding">
        <div className="container">
          {/* Section Title Start */}
          <div className="row">
            <div className="col-md-6">
              <div className="section-title text-right">
                <span className="title-top with-border">About Us</span>
                <h1>Welcome To Alcyone Summerstrand</h1>
              </div>
            </div>
            <div className="col-md-6">
              <p className="mb-30">
                Alcyone is a small family-run guesthouse situated in the laid-back
                suburb of Summerstrand, Gqeberha (the city formerly known as Port Elizabeth). The guesthouse was started in
                2011 and has been running successfully ever since. The newest owners Greg and Rosalie (that's us!) 
                took over ownership and operations in 2021.
              </p>
              <p>
                Owning a guesthouse has been a longtime dream of ours, and for us this is a 
                long-term journey to turn Alcyone into a relaxing space that guests will want to return to again and again.
                As a first step we started with some
                much-needed light renovations and wholesale decor updates in all
                the existing guest units. We subscribe to the principle of
                continuous improvement and as such we already have some further updates and improvements in mind.
                We believe that the needs of our guests are the core of our business and we love and value feedback 
                from visitors to ensure we keep moving forward and keep improving the guest experience.
                Be sure to check back every now and then, as we'll be documenting our unit updates as they are completed.
              </p>

            </div>
          </div>
          {/* Section Title End */}
          <div
            className="about-img bg-image-center"
            style={{
              backgroundImage: "url(" + publicUrl + "assets/img/about/about-us-1170x570.jpg)",
            }}
          />
          <div className="row no-gutters">
            <div className="offset-xl-1 col-xl-5 col-md-6">
              <div className="about-text-box section-bg">
                <h2>Relaxed Atmosphere and Spacious Rooms</h2>
                <p>
                  We are situated in the heart of Summerstrand, close to
                  multiple beaches, Humewood golf course, Nelson Mandela Bay
                  University and the Cape Recife Nature Reserve.{" "}
                </p>
                <a href="#/room-grid" className="btn filled-btn">
                  Look Around <i className="far fa-long-arrow-right" />
                </a>
              </div>
            </div>
            {/* <div className="offset-lg-1 col-lg-5 col-md-6">
              <div className="counter">
                <div className="row">
                  <div className="col-6">
                    <div className="counter-box">
                      <img src="assets/img/icons/building.png" alt="" />
                      <span className="counter-number">506</span>
                      <p>Luxury Appartment</p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="counter-box">
                      <img src="assets/img/icons/trophy.png" alt="" />
                      <span className="counter-number">420</span>
                      <p>Win Int Awards</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    );
  }
}

export default AboutPage;
