import React, { Component } from "react";

class Contact extends Component {
  render() {
    return (
      <section className="contact-section section-padding">
        <div className="container">
          <div className="row align-items-center no-gutters">
            <div className="col-lg-6">
              <div className="mapouter">
                <div
                  style={{
                    overflow: "hidden",
                    width: "585px",
                    position: "relative",
                  }}
                >
                  <iframe
                    title="Alcyone"
                    width={585}
                    height={580}
                    src="https://maps.google.com/maps?width=1920&height=670&hl=en&q=Alcyone%20Summerstrand%2C%20Gqeberhe%20&ie=UTF8&t=&z=10&iwloc=B&output=embed"
                    frameBorder={0}
                    scrolling="no"
                    marginHeight={0}
                    marginWidth={0}
                  />
                  <div
                    style={{
                      position: "absolute",
                      width: "80%",
                      bottom: "10px",
                      left: 0,
                      right: 0,
                      marginLeft: "auto",
                      marginRight: "auto",
                      color: "#000",
                      textAlign: "center",
                    }}
                  >
                    <small
                      style={{
                        lineHeight: "1.8",
                        fontSize: "2px",
                        background: "#fff",
                      }}
                    >
                      Powered by{" "}
                      <a href="https://embedgooglemaps.com/es/">
                        Embedgooglemaps ES
                      </a>{" "}
                      &amp;{" "}
                      <a href="https://www.jubler.org/">
                        increase website traffic
                      </a>
                    </small>
                  </div>
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        "#gmap_canvas img{max-width:none!important;background:none!important}",
                    }}
                  />
                </div>
                <br />
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              {/* Section Title */}
              <div className="section-title">
                <span className="title-top with-border">Have any questions? </span>
                <h1>Get in touch. We're a friendly bunch :)</h1>
                <p>
                You can contact us on all the usual channels, including email and phone. Send a Whatsapp for a quick response.
                </p>
              </div>
              <div
                className="contact-box wow fadeInUp animated"
                data-wow-duration="1500ms"
                data-wow-delay="400ms"
              >
                <ul>
                  <li>
                    <i className="far fa-map-marker-alt" />
                    29 Admiralty Way, Summerstrand, Gqeberha
                  </li>
                  <li>
                    <p>
                      <i className="far fa-envelope-open" />
                      alcyone@innisfree.tech
                    </p>
                  </li>
                  <li>
                    <p>
                      <i className="far fa-phone" />
                      +27 082 783 3204
                    </p>
                  </li>
                  <li>
                    <p>
                      <i className="far fa-phone" />
                      +27 082 455 0041
                    </p>
                  </li>				  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
