import React, { Component } from "react";

class Welcome extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <section className="welcome-section section-padding">
        <div className="container">
          <div className="row align-items-center no-gutters">
            {/* Tile Gallery */}
            <div className="col-lg-6">
              <div className="tile-gallery">
                <img
                  src={
                    publicUrl +
                    "assets/img/home-welcome/greg-and-rosalie-570x745.jpg"
                  }
                  alt="Tile Gallery"
                />
                <div className="tile-gallery-content">
                  <div className="tile-icon">
                    <img
                      src={publicUrl + "assets/img/icons/hostel-hover.png"}
                      alt=""
                    />
                  </div>
                  <h3>Laid Back</h3>
                  <p>Frankie says Relax, and we have no reason to argue.</p>
                </div>
              </div>
            </div>
            {/* End tile Gallery */}
            <div className="col-lg-5 offset-lg-1">
              {/* Section Title */}
              <div className="section-title">
                <span className="title-top with-border">About Us</span>
                <h1>Welcome To Alcyone</h1>
                <p>
                  Alcyone is a small family-run guesthouse situated in the laid-back
                  suburb of Summerstrand, Gqeberha (the city formerly known as Port Elizabeth). Our number one goal 
                  is to make your holiday as relaxing as possible.{" "}
                </p>
                <br />
                <p>
                  Accommodation is offered in 4 private en-suite units that can comfortably 
                  accommodate 2 guests each. Each unit is equipped with a
                  king-size bed, kitchenette, dining corner, bar fridge, smart TV with
                  Google Chromecast, and an en-suite bathroom fitted with a
                  shower. The 4 guest units share a patio and braai area. After a long day of swimming, surfing or just lying on the beach 
                  you can come home and throw some fish or a piece of boerewors on the braai whilst enjoying a cold beer or glass of wine.
                </p>
                <br />
                <p>
                  Your hosts are Greg and Rosalie (that's us in the photo!). When we are not moonlighting as pirates or running the guesthouse 
                  we both work in the technology industry. We know the fast pace of a highly demanding job, so we especially understand 
                  that our guests need a safe, comfortable and relaxing space to help them unwind and forget about their stress for a little while.
                  We also enjoy many other interests such as photography, horse riding, hiking, cycling, and
                  birding.{" "}
                </p>
                <br />
                <p>
                  We understand that your holiday time is precious and try to
                  ensure that you can put your suitcase down and take it easy
                  while staying with us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Welcome;
