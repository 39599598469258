import React, { useEffect } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import RoomList from "./section-components/room-grid";
import Footer from "./global-components/footer";
import AnalyticsPageViews from "./global-components/analytics-page-views";

const RoomGridPage = () => {
  useEffect(() => {
    AnalyticsPageViews(window.location.pathname, "Room-Grid");
  }, []);
  
  return (
    <div>
      <Navbar />
      <PageHeader
        headertitle="Our Rooms"
        subheader="Self-Catering Units"
        BigText="Rooms"
        Img="rooms//rooms-page-header-2-1920x510.jpg"
      />
      <RoomList />
      <Footer />
    </div>
  );
};

export default RoomGridPage;
