import React, { Component } from "react";
import { Link } from "react-router-dom";

class CallToAction extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <section
        className="cta-section bg-img-center"
        style={{
          backgroundImage:
            "url(" +
            publicUrl +
            "assets/img/home-call-to-action/book-now-1920x600.jpg)",
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-10">
              <div className="cta-left-content avson-go-top">
                <span>No need to search further</span>
                <h1>Book Now</h1>
                <Link to="/contact" className="btn filled-btn">
                  Book Now <i className="far fa-long-arrow-right" />
                </Link>
              </div>
            </div>
            {/* <div className="col-md-2">
			        <div className="video-icon text-right">
			          <a href="https://www.youtube.com/watch?v=NpEaa2P7qZI" className="video-popup"> <i className="fas fa-play" /></a>
			        </div>
			      </div> */}
          </div>
        </div>
      </section>
    );
  }
}

export default CallToAction;
