import React from "react";
import { Route, Switch } from "react-router-dom";
//import ReactGA4 from "react-ga4";
import Home from "./components/home";
import RoomGrid from "./components/room-grid";
import RoomDetailsLighthouse from "./components/room-details-lighthouse";
import RoomDetailsBeach from "./components/room-details-beach";
import RoomDetailsAddo from "./components/room-details-addo";
import RoomDetailsLittleAddo from "./components/room-details-littleaddo";
import RoomDetailsFamily from "./components/room-details-family";
import Contact from "./components/contact";
import About from "./components/about";
import Faq from "./components/faq";

export default function App() {

  //console.log("Page =",window.location.pathname);
  //ReactGA4.send({ hitType: "pageview", page: window.location.pathname });

  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/room-grid" component={RoomGrid} />
        <Route
          path="/room-details-lighthouse"
          component={RoomDetailsLighthouse}
        />
        <Route path="/room-details-beach" component={RoomDetailsBeach} />
        <Route path="/room-details-addo" component={RoomDetailsAddo} />
        <Route
          path="/room-details-littleaddo"
          component={RoomDetailsLittleAddo}
        />
        <Route path="/room-details-family" component={RoomDetailsFamily} />
        <Route path="/contact" component={Contact} />
        <Route path="/about" component={About} />
        <Route path="/faq" component={Faq} />
      </Switch>
    </React.Fragment>
  );
}
