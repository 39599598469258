import React, { Component } from "react";
import { Link } from "react-router-dom";

class Slider extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let sliderImage = this.props.sliderImage
    let heading = this.props.heading ? this.props.heading : "Welcome to Alcyone";
    let shortDescription = this.props.shortDescription
    return (
      <div
      className="single-hero-slide bg-img-center d-flex align-items-center"
      style={{
        backgroundImage:
          "url(" + publicUrl + sliderImage + ")",
      }}
    >      
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-10">
                <div className="slider-text">
                  <h1 data-animation="fadeInDown" data-delay=".3s">
                  {heading}{" "}
                  </h1>
                  <p data-animation="fadeInLeft" data-delay=".5s">
                    {shortDescription}{" "}
                  </p>
                  <Link
                    className="btn filled-btn"
                    to="/room-grid"
                    data-animation="fadeInUp"
                    data-delay=".8s"
                  >
                    look around <i className="far fa-long-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default Slider;
