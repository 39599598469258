import React, { useEffect } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import Faq from "./section-components/faq";
import Footer from "./global-components/footer";
import AnalyticsPageViews from "./global-components/analytics-page-views";

const FaqPage = () => {
  
  useEffect(() => {
    AnalyticsPageViews(window.location.pathname, "FAQ");
  }, []);
  
  return (
    <div>
      <Navbar />
      <PageHeader
        headertitle="FAQ"
        BigText="FAQ"
        Img="faq//faq-page-header-1920x510.jpg"
      />
      <Faq />
      <Footer />
    </div>
  );
};

export default FaqPage;
