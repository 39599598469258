import React, { Component } from "react";
import RoomSummaryCard from "./room-summary-card";

class RoomGrid extends Component {
  render() {
    return (
      <section className="rooms-warp gird-view section-bg section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 avson-go-top">
              <div className="row">
                <div className="col-md-6">
                  <RoomSummaryCard
                    title="Lighthouse Suite"
                    path="/room-details-lighthouse"
                    cardImage="assets/img/rooms/lighthouse-suite/lighthouse-grid-image-770x430.jpg"
                    imageAlt="Lighthouse suite image"
                    sleeps="2"
                    priceFrom="R 650.00"
                    shortDescription="The Lighthouse Suite takes its inspiration from the historic lighthouse 
                    that was built in 1849 on Cape Recife. It is a private en-suite unit that sleeps 2 people 
                    with the option of a king-size bed or 2 single beds."
                  />
                </div>
                <div className="col-md-6">
                  <RoomSummaryCard
                    title="Beach Suite"
                    path="/room-details-beach"
                    cardImage="assets/img/rooms/beach-suite/beach-grid-image-770x430.jpg"
                    imageAlt="Beach suite image"
                    sleeps="2"
                    priceFrom="R 650.00"
                    shortDescription="The beautiful sandy beaches of the Eastern Cape and the
                    turquoise waters of the Indian Ocean provided the
                    inspiration for the Beach Suite. It is a private
                    en-suite unit that sleeps 2 people with the option of a
                    king-size bed or 2 single beds."
                  />                  
                </div>
                <div className="col-md-6">
                <RoomSummaryCard
                    title="Addo Suite"
                    path="/room-details-addo"
                    cardImage="assets/img/rooms/addo-suite/addo-grid-image-770x430.jpg"
                    imageAlt="Addo suite image"
                    sleeps="2"
                    priceFrom="R 700.00"
                    shortDescription="The Addo Elephant Park inspires the elephant theme for
                    our Addo Suite. It is a private en-suite unit that
                    sleeps 2 people with the option of a king-size bed or 2
                    single beds."
                  />   
                </div>
                <div className="col-md-6">
                <RoomSummaryCard
                    title="Little Addo Suite"
                    path="/room-details-littleaddo"
                    cardImage="assets/img/rooms/littleaddo-suite/little-addo-grid-image-770x430.jpg"
                    imageAlt="Little Addo suite image"
                    sleeps="2"
                    priceFrom="R 650.00"
                    shortDescription="The Addo Elephant Park inspires the elephant theme for
                    our Little Addo Suite. It is a private en-suite unit
                    that sleeps 2 people with the option of a king-size bed
                    or 2 single beds."
                  />                    
                </div>
                <div className="col-md-6">
                <RoomSummaryCard
                    title="Family Suite"
                    path="/room-details-family"
                    cardImage="assets/img/rooms/family-unit/family-unit-grid-image-770x430.jpg"
                    imageAlt="Family unit image"
                    sleeps="4 (or 5)"
                    priceFrom="R 1350.00"
                    shortDescription="The Addo and Little Addo Suites can be joined as a
                    family unit that sleeps 4 (or potentially 5, at an extra fee). Both rooms have en-suite
                    bathrooms."
                  />                     
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default RoomGrid;
