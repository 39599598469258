import React, { useEffect } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import Footer from "./global-components/footer";
import RoomDetails from "./section-components/room-details";
import OtherRoomsList from "./section-components/other-rooms-list";
import AnalyticsPageViews from "./global-components/analytics-page-views";

const RoomDetailsAddo = () => {
  useEffect(() => {
    AnalyticsPageViews(window.location.pathname, "Room-Details-Addo");
  }, []);
  
  const sliders = [
    // {
    //   path: "assets/img/rooms/addo-suite/details/ad-mainslider-1920x1080-01.jpg",
    //   altText: "Pic 1",
    // },
    {
      path: "assets/img/rooms/addo-suite/addo-slider-1920-1080-01.jpg",
      altText: "Addo Suite Bed",
    },    
    {
      path: "assets/img/rooms/addo-suite/addo-slider-1920-1080-02.jpg",
      altText: "Addo Suite Bathroom",
    },
    {
      path: "assets/img/rooms/addo-suite/addo-slider-1920-1080-03.jpg",
      altText: "Addo Suite Bed from Angle",
    },    
    {
      path: "assets/img/rooms/addo-suite/addo-slider-1920-1080-04.jpg",
      altText: "Addo Suite Bathroom Counter",
    },
    {
      path: "assets/img/rooms/addo-suite/addo-slider-1920-1080-05.jpg",
      altText: "Addo Suite Wide View",
    },
    // {
    //   // TODO: replace with correctly sized image
    //   path: "assets/img/rooms/addo-suite/details/ad-mainslider-770x430-04.jpg",
    //   altText: "Pic 4",
    // },
    // {
    //   // TODO: replace with correctly sized image
    //   path: "assets/img/rooms/addo-suite/details/ad-mainslider-770x430-05.jpg",
    //   altText: "Pic 5",
    // },
    // {
    //   // TODO: replace with correctly sized image
    //   path: "assets/img/rooms/addo-suite/details/ad-mainslider-770x430-06.jpg",
    //   altText: "Pic 6",
    // },
    // {
    //   // TODO: replace with correctly sized image
    //   path: "assets/img/rooms/addo-suite/details/ad-mainslider-770x430-07.jpg",
    //   altText: "Pic 7",
    // },
    // {
    //   // TODO: replace with correctly sized image
    //   path: "assets/img/rooms/addo-suite/details/ad-mainslider-770x430-08.jpg",
    //   altText: "Pic 8",
    // },
  ];

  const features = [
    "En-suite bathroom with Shower",
    "King Size Bed",
    "Smart TV",
    "Netflix",
    "Google Chromecast",
    "Fridge",
    "Microwave",
    "Gas Hob",
    "Coffee/Tea Station",
    "Dining Table",
  ];

  const description = `The Addo Elephant Park inspires the elephant theme for our Addo Suite. 
    It is a private en-suite unit that sleeps 2 people with the option of a 
    king-size bed or 2 single beds. There is a kitchenette with all the basics 
    you will need, such as a kettle, gas hob, microwave, fridge and toaster. 
    Other facilities include a smart TV with internet access and a Chromecast 
    device that can be connected to guests' smartphone to stream Netflix, 
    Youtube or the DStv app. The private en-suite bathroom is fitted with a shower. 
    The room faces onto a communal open terrace with tables, chairs and braai facilities. 
    Free wifi is available in all the rooms. Please note that all our rooms are non-smoking, 
    and regrettably no pets are allowed.`;

  return (
    <div>
      <Navbar />
      <PageHeader
        headertitle="Addo Suite"
        BigText="Addo"
        Img="rooms/addo-suite/addo-header-1920x510.jpg"
      />
      <RoomDetails
        title="Addo Suite"
        priceFrom="R 700.00"
        mainImage="assets/img/rooms/addo-suite/addo-main-image-1920x1440.jpg"
        sliderImages={sliders}
        sleeps="2"
        size="34 sq m"
        description={description}
        midweekRate="R 700.00"
        weekendRate="R 750.00"
        highSeasonRate="R 800.00"
        minDays="2"
        features={features}
      />
      <OtherRoomsList
        showAddo={false}
        showLighthouse={true}
        showBeach={true}
        showLittleAddo={false}
        showFamily={true}
      />
      <Footer />
    </div>
  );
};

export default RoomDetailsAddo;
