import React, { Component } from "react";
import { Link } from "react-router-dom";

class Navbar extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <header>
        <div className="header-top-area section-bg">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-xl-4 col-lg-7 offset-xl-3 col-md-6">
                <ul className="top-contact-info list-inline">
                  <li>
                    <i className="far fa-map-marker-alt" />
                    29 Admiralty Way, Summerstrand, Gqeberha
                  </li>
                  <li>
                    <i className="far fa-phone" />
                    +27 (082) 783 3204
                  </li>
                </ul>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-6">
                <div className="top-right text-right">
                  <ul className="top-social-icon list-inline d-inline">
                    <li>
                      <a
                        href="https://www.facebook.com/Alcyone-Summerstrand-101297952435717"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/StayAtAlcyone"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/stayatalcyone/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-menu-area">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-xl-3 col-lg-3 col-md-4 col-7">
                <div className="logo">
                  <Link to="/">
                    <img
                      src={publicUrl + "assets/img/logo.png"}
                      alt="Alcyone"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-xl-9 col-lg-9 col-md-8 col-5">
                <div className="menu-right-area text-right">
                  <nav className="main-menu">
                    <ul className="list-inline">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li className="have-submenu">
                        <Link to="/room-grid">Rooms</Link>
                        <ul className="submenu">
                          <li>
                            <Link to="/room-details-lighthouse">
                              Lighthouse Suite
                            </Link>
                          </li>
                          <li>
                            <Link to="/room-details-addo">Addo Suite</Link>
                          </li>
                          <li>
                            <Link to="/room-details-littleaddo">
                              Little Addo Suite
                            </Link>
                          </li>
                          <li>
                            <Link to="/room-details-beach">Beach Suite</Link>
                          </li>
                          <li>
                            <Link to="/room-details-family">Family Unit</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                      <li>
                        <Link to="/faq">FAQ</Link>
                      </li>                      
                    </ul>
                  </nav>
                  <div className="search-wrap">
                    <p className="search-icon">
                      <i className="far fa-search" />
                    </p>
                    <p className="search-icon icon-close">
                      <i className="far fa-times" />
                    </p>
                    {/* <div className="search-form">
			                <form>
			                  <input type="search" placeholder="TYPE AND PRESS ENTER....." />
			                </form>
			              </div> */}
                  </div>
                  <div className="quote-btn">
                    <Link to="/contact" className="btn filled-btn">
                      Make an Enquiry <i className="far fa-long-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobilemenu" />
          </div>
        </div>
      </header>
    );
  }
}

export default Navbar;
