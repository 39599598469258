import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  componentDidMount() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <footer>
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="widget footer-widget">
                  <div className="footer-logo">
                    <img src={publicUrl + "assets/img/logo.png"} alt="Logo" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="widget footer-widget">
                  <p>
                    We're on the interwebs with all the cool kids too. Come say
                    hello.
                  </p>
                  <ul className="social-icons">
                    <li>
                      <a
                        href="https://www.facebook.com/Alcyone-Summerstrand-101297952435717"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/StayAtAlcyone"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/stayatalcyone/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            {/* <a href="#" className="back-to-top">
              <i className="far fa-angle-up" />
            </a> */}
            <div className="row">
              <div className="col-md-6 avson-go-top">
                <ul className="footer-nav">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/room-grid">Rooms</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <p className="copy-right text-right">
                  Copyright ©2022. All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
