import React, { Component } from "react";
import OtherRoomCard from "./other-room-card";

class OtherRoomsList extends Component {
  render() {
    let showAddo = this.props.showAddo;
    let showLighthouse = this.props.showLighthouse;
    let showBeach = this.props.showBeach;
    let showLittleAddo = this.props.showLittleAddo;
    let showFamily = this.props.showFamily;
    return (
      <section className="latest-room-d section-bg section-padding">
        <div className="container">
          <div className="section-title text-center">
            <span className="title-top">See More</span>
            <h1>Check Out Our Other Rooms</h1>
          </div>
          <div className="row">
            {showAddo && (
              <div className="col-lg-4 col-md-6">
                <OtherRoomCard
                  title="Addo Suite"
                  path="/room-details-addo"
                  cardImage="assets/img/rooms/addo-suite/addo-card-370x250.jpg"
                  imageAlt="Addo Suite image"
                  sleeps="2"
                  size="34 m"
                  priceFrom="R 600.00"
                  shortDescription="The Addo Elephant Park inspires the elephant theme for our Addo Suite. It is a private en-suite unit that sleeps 2 
                people with the option of a king-size bed or 2 single beds. The kitchen has a 4-plate gas hob."
                />
              </div>
            )}

            {showLittleAddo && (
              <div className="col-lg-4 col-md-6">
                <OtherRoomCard
                  title="Little Addo Suite"
                  path="/room-details-littleaddo"
                  cardImage="assets/img/rooms/littleaddo-suite/little-addo-card-370x250.jpg" 
                  imageAlt="Little Addo Suite image"
                  sleeps="2"
                  size="26 m"
                  priceFrom="R 500.00"
                  shortDescription="The Addo Elephant Park inspires the elephant theme for
                  our Little Addo Suite. It is a private en-suite unit
                  that sleeps 2 people with the option of a king-size bed
                  or 2 single beds."
                />
              </div>
            )}

            {showLighthouse && (
              <div className="col-lg-4 col-md-6">
                <OtherRoomCard
                  title="Lighthouse Suite"
                  path="/room-details-lighthouse"
                  cardImage="assets/img/rooms/lighthouse-suite/lighthouse-card-370x250.jpg"
                  imageAlt="Lighthouse suite image"
                  sleeps="2"
                  size="28 m"
                  priceFrom="R 500.00"
                  shortDescription="The Lighthouse Suite takes its inspiration from the historic lighthouse that was built in 1849 on Cape Recife. 
            It is a private en-suite unit that sleeps 2 people with the option of a king-size bed or 2 single beds."
                />
              </div>
            )}

            {showBeach && (
              <div className="col-lg-4 col-md-6">
                <OtherRoomCard
                  title="Beach Suite"
                  path="/room-details-beach"
                  cardImage="assets/img/rooms/beach-suite/beach-card-370x250.jpg"
                  imageAlt="Beach suite image"
                  sleeps="2"
                  size="25 m"
                  priceFrom="R 500.00"
                  shortDescription="The sandy Eastern Cape beaches and the Indian Ocean's turquoise waters provided the 
            inspiration for the Beach Suite. It is a private en-suite unit that sleeps 2 people with the option of a king-size bed or 2 single beds."
                />
              </div>
            )}

            {showFamily && (
              <div className="col-lg-4 col-md-6">
                <OtherRoomCard
                  title="Family Unit"
                  path="/room-details-family"
                  cardImage="assets/img/rooms/family-unit/family-card-370x250.jpg"
                  imageAlt="Family unit image"
                  sleeps="4 (or 5)"
                  size="61 m"
                  priceFrom="R 1100.00"
                  shortDescription="The Addo and Little Addo Suites can be joined as a family unit that sleeps 4. Both rooms have en-suite bathrooms. 
                      An extra single bed can be added to the Little Addo Suite to accommodate one additional person."
                />
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default OtherRoomsList;
