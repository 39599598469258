import React, { useEffect } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import About from "./section-components/about";
import Footer from "./global-components/footer";
import AnalyticsPageViews from "./global-components/analytics-page-views";

const AboutPage = () => {
  
  useEffect(() => {
    AnalyticsPageViews(window.location.pathname, "About");
  }, []);
  
  return (
    <div>
      <Navbar />
      <PageHeader
        headertitle="About Us"
        BigText="About"
        Img="about//about-page-header-1920x510.jpg"
      />
      <About />
      <Footer />
    </div>
  );
};

export default AboutPage;
