import React from "react";

const FaqPage = () => {
  return (
    <section className="about-site section-padding">
      <div className="container">
        {/* Section Title Start */}
        <div className="row">
          <div className="col-md-4">
            <div className="section-title text-right">
              <span className="title-top with-border">Check-in and Check-out Times</span>
            </div>
          </div>
          <div className="col-md-8">
            <p className="mb-30">
            Please note that Check-in time on arrival date is after 2PM. These times allow us to ensure your room is in perfect shape when you arrive.
            </p>
            <p className="mb-30">
              In case you need to check in earlier, please get in touch with us. Depending on whether the room was booked on the previous night, we might be able to 
              help out. If the room is not ready yet, you are more than welcome to drop off your suitcases while you explore the area.
            </p>
            <p className="mb-30">
            Check-out time on departure date is before 10AM. If you need to leave early in the morning, please arrange with us the night before you leave.
            </p>                       
          </div>
        </div>  
        <div className="row faq-row">
          <div className="col-md-4">
            <div className="section-title text-right">
              <span className="title-top with-border">Cooking facilities</span>
            </div>
          </div>
          <div className="col-md-8">
            <p className="mb-30">
            All our rooms include a small kitchenette which we have equipped with everything that you should need to prepare basic meals. 
            This includes a microwave, mini-oven, pots & pans and a small fridge/freezer.
            </p>
            <p className="mb-30">
            There are also a couple of braai facilities for guests to use, one of which is a Weber, for the braai-masters to show off their skills.
            </p>
            <p className="mb-30">
            Of course, if you need anything additional, please just ask, we will do whatever possible to ensure you have a super-comfortable stay.
            </p>             
          </div>
        </div> 
        <div className="row faq-row">
          <div className="col-md-4">
            <div className="section-title text-right">
              <span className="title-top with-border">Television, DSTV and Internet</span>
            </div>
          </div>
          <div className="col-md-8">
            <p className="mb-30">
            All rooms have access to our on-site wifi network and we will provide access details on arrival.
            </p>
            <p className="mb-30">
            Every room is equipped with an internet-connected smart TV, as well as Google Chromecast and Netflix account. This should keep you entertained while indoors. 
            </p>
            <p className="mb-30">
            Note that <strong>we do not have DSTV connected in any of the rooms</strong>, but if you use the DSTV Now app you can easily cast to the television (Just in case there's an important game on)
            </p>                         
          </div>
        </div>   
        <div className="row faq-row">
          <div className="col-md-4">
            <div className="section-title text-right">
              <span className="title-top with-border">COVID protocols</span>
            </div>
          </div>
          <div className="col-md-8">
            <p className="mb-30">
            Before arrival (at least 2 hours) your room will be thoroughly cleaned and sanitised, this includes all surfaces, whether dirty or not.
            </p>
            <p className="mb-30">
            Your room is supplied with a container filled with sanitiser.
            </p>
          </div>
        </div>
        <div className="row faq-row">
          <div className="col-md-4">
            <div className="section-title text-right">
              <span className="title-top with-border">Room cleaning</span>
            </div>
          </div>
          <div className="col-md-8">
            <p className="mb-30">
            Our guest rooms are cleaned using a strict checklist before guests arrive, including fresh linens and all related consumables.
            </p>
            <p className="mb-30">
            Please note that <strong>we do not clean rooms (or enter rooms for any reason) during your stay with us</strong>. If you require a change of linens, please let us know and we will supply a fresh set.
            </p>
          </div>
        </div>                           
        <div className="row faq-row">
          <div className="col-md-4">
            <div className="section-title text-right">
              <span className="title-top with-border">Laundry facilities</span>
            </div>
          </div>
          <div className="col-md-8">
            <p className="mb-30">
            Our guest house has a washing machine available for your use. If you need to wash some items please just let us know and we'll supply washing powder.
            </p>
            <p className="mb-30">
            If you'd prefer to leave laundry services to the professionals, there are also a number of laundromats in the area.
            </p>
          </div>
        </div>     
        <div className="row faq-row">
          <div className="col-md-4">
            <div className="section-title text-right">
              <span className="title-top with-border">Address and directions</span>
            </div>
          </div>
          <div className="col-md-8">
            <p className="mb-30">
            We are located at 29 Admiralty Way in Summerstrand, right on the corner of Admiralty and Tilbury Road. There is a small sign on the front, people sometimes miss it.
            </p>
            <p className="mb-30">
            If you need additional directions, or a GPS pin, just let us know and we will gladly assist.
            </p>
          </div>
        </div>
        <div className="row faq-row">
          <div className="col-md-4">
            <div className="section-title text-right">
              <span className="title-top with-border">Beach and other attractions</span>
            </div>
          </div>
          <div className="col-md-8">
            <p className="mb-30">
            We are located about 600m from the beach, which is a short 10 minute walk. The nearest life-guard-monitored beach is Pollok beach,
            about 1.5km away. Hobie, Humewood and Kings beaches are a bit further away (between 2 and 5km) and probably makes sense to rather drive than walk.
            </p>
            <p className="mb-30">
            If you're visiting for a graduation ceremony. we're right next to the Nelson Mandela University.
            </p>
          </div>
        </div>        
        <div className="row faq-row">
          <div className="col-md-4">
            <div className="section-title text-right">
              <span className="title-top with-border">Parking</span>
            </div>
          </div>
          <div className="col-md-8">
            <p className="mb-30">
            There is 1 dedicated parking space per room. If you require additional parking space (or if you will be travelling with a trailer), please let us know so we can make arrangements beforehand.
            </p>
          </div>
        </div>  
        <div className="row faq-row">
          <div className="col-md-4">
            <div className="section-title text-right">
              <span className="title-top with-border">Payment Options</span>
            </div>
          </div>
          <div className="col-md-8">
            <p className="mb-30">
            To secure your booking, we typically require a 50% deposit. This amount is fully refundable if the booking is 
            cancelled more than 30 days before your stay.
            </p>
            <p className="mb-30">
            In case of bookings made within 1 week of the arrival date, the deposit requirement is 100%.
            </p>
            <p className="mb-30">
            Please note that since we are a small, family-run guest house we unfortunately do not have card facilities. 
            Payment should preferably be made via EFT - Our banking details are provided on the invoice.
            </p>             
          </div>
        </div>         
        <div className="row faq-row">
          <div className="col-md-4">
            <div className="section-title text-right">
              <span className="title-top with-border">Water and water shortages</span>
            </div>
          </div>
          <div className="col-md-8">
            <p className="mb-30">
            You are probably aware that Port Elizabeth (and many other parts of the Eastern Cape) are currently suffering severe water shortages. We request all our guests to be mindful of this issue and take short showers and generally be water-wise. Although some of our guest rooms include a bath, a shower is much more water efficient, and we would encourage showering instead.
            </p>
            <p className="mb-30">
            Although the tap water is fine for drinking, we do supply bottled water as an added convenience to our guests. You'll find a 5lt container in your room, and if needed, we will replace it every 2/3 days.
            </p>
          </div>
        </div>   
        <div className="row faq-row">
          <div className="col-md-4">
            <div className="section-title text-right">
              <span className="title-top with-border">Weather</span>
            </div>
          </div>
          <div className="col-md-8">
            <p className="mb-30">
            Port Elizabeth weather can be strange at times. In general we have moderately warm and sunny days, but depending on the wind, it can get quite chilly in the early mornings and evenings. We'd suggest packing at least a warm jacket and definitely bring a rain coat :)
            </p>
          </div>
        </div>  
        <div className="row faq-row">
          <div className="col-md-4">
            <div className="section-title text-right">
              <span className="title-top with-border">Pets</span>
            </div>
          </div>
          <div className="col-md-8">
            <p className="mb-30">
            While we are thorough animal lovers, our guest house is NOT equipped to handle guest pets.
            </p>
          </div>
        </div>     
        <div className="row faq-row">
          <div className="col-md-4">
            <div className="section-title text-right">
              <span className="title-top with-border">Smoking</span>
            </div>
          </div>
          <div className="col-md-8">
            <p className="mb-30">
            Please note that smoking is not allowed in our guest rooms. We do provide an outside smoking area with ashtrays, if required.
            </p>
          </div>
        </div>   
        <div className="row faq-row">
          <div className="col-md-4">
            <div className="section-title text-right">
              <span className="title-top with-border">Towels</span>
            </div>
          </div>
          <div className="col-md-8">
            <p className="mb-30">
            Your room is provided with a fresh set of bath towels on arrival. Please do not remove the bath towels from the units, especially not for taking them to the beach.
            </p>
            <p className="mb-30">
            We request our guests to supply their own beach towels, but if you have forgotten, please rather get in touch with us and we can make a plan.
            </p>     
            <p className="mb-30">
            Bathroom towels will be replaced on request, please just let us know and we'll supply a fresh set.
            </p>                     
          </div>
        </div>                                                         
      </div>     
    </section>
  );
};

export default FaqPage;
