import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import ReactGA4 from "react-ga4";
import App from "./App";

ReactGA4.initialize("G-PCGRPCSGEV");

ReactDOM.render(
  <BrowserRouter>
    <App/>
  </BrowserRouter>, 
  document.getElementById("alcyone")
);
