import React, { Component } from "react";
import { Link } from "react-router-dom";

class OtherRoomCard extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let priceFrom = this.props.priceFrom ? this.props.priceFrom : "UNKNOWN";
    let title = this.props.title ? this.props.title : "UNKNOWN";
    let path = this.props.path ? this.props.path : "/room-grid";
    let cardImage = this.props.cardImage
      ? this.props.cardImage
      : "assets/img/rooms/room-card-image-370x250.jpg";
    let shortDescription = this.props.shortDescription
      ? this.props.shortDescription
      : "UNKNOWN";
    let sleeps = this.props.sleeps ? this.props.sleeps : "2";
    let size = this.props.size ? this.props.size : "UNKNOWN";
    let imageAlt = this.props.imageAlt ? this.props.imageAlt : "room";

    return (
        <div className="single-room">
          <div className="room-thumb">
            <Link to={path}>
              <img src={publicUrl + cardImage} alt={imageAlt} />
            </Link>
          </div>
          <div className="room-desc">
            <h4>
              <Link to={path}>{title}</Link>
            </h4>
            <p>{shortDescription} </p>
            <ul className="room-info list-inline">
              <li>
                <i className="far fa-bed" />
                Sleeps {sleeps}
              </li>
              <li>
                <i className="far fa-bath" />
                En-suite
              </li>
              <li>
                <i className="far fa-ruler-triangle" />
                {size}
              </li>
            </ul>
            <div className="room-price">
              <p>From {priceFrom} per night</p>
            </div>
          </div>
        </div>
    );
  }
}

export default OtherRoomCard;
