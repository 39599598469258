import React, { Component } from "react";
import { Link } from "react-router-dom";

class RoomDetails extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let priceFrom = this.props.priceFrom ? this.props.priceFrom : "UNKNOWN";
    let title = this.props.title ? this.props.title : "UNKNOWN";
    let mainImage = this.props.mainImage ? this.props.mainImage : "assets/img/rooms/room-main-image-placeholder-1920x1080.jpg";
    let sliderImages = this.props.sliderImages;
    if (!sliderImages) {
      sliderImages = [ {
        path: 'assets/img/rooms/room-slider-placeholder-1920x1080.jpg',
        altText: 'Image'
      }];
    }
    let features = this.props.features;
    if (!features) {
      features = []
    }
    let sleeps = this.props.sleeps ? this.props.sleeps : "2";
    let size = this.props.size ? this.props.size : "UNKNOWN";
    let description = this.props.description ? this.props.description : "UNKNOWN";
    let midweekRate = this.props.midweekRate ? this.props.midweekRate : "UNKNOWN";
    let weekendRate = this.props.weekendRate ? this.props.weekendRate : "UNKNOWN";
    let highSeasonRate = this.props.highSeasonRate ? this.props.highSeasonRate : "UNKNOWN";
    let minDays = this.props.minDays ? this.props.minDays : "UNKNOWN";
    let maxDays = this.props.maxDays ? this.props.maxDays : "28";


    return (
      <section className="room-details-wrapper section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="room-details">
                <div className="entry-header">

                  {/* Sliders */}
                  <div className="post-thumb position-relative">
                    <div className="post-thumb-slider">
                      <div className="main-slider">
                        { sliderImages.map((slider, i) => {
                          const url = publicUrl + slider.path;
                          return ( 
                              <div className="single-img" key={i}> 
                                <a href={url} className="main-img"> 
                                  <img src={url} alt={slider.altText} /> 
                                </a> 
                              </div>  
                          );
                        })}
                      </div>
                    </div>
                    <div className="price-tag">From {priceFrom} per night</div>
                  </div>

                  {/* Basics */}
                  <div className="room-cat">
                    <Link to="/">Back to Main Page</Link>
                  </div>
                  <h2 className="entry-title">{title}</h2>
                  <ul className="entry-meta list-inline">
                    <li>
                      <i className="far fa-bed" />
                      Sleeps {sleeps}
                    </li>
                    <li>
                      <i className="far fa-bath" />
                      En-suite
                    </li>
                    <li>
                      <i className="far fa-ruler-triangle" />
                      {size}
                    </li>
                  </ul>
                </div>

                {/* Description */}
                <div className="room-details-tab">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="tab-content desc-tab-content">
                        <div
                          role="tabpanel"
                          className="tab-pane fade in active show"
                          id="desc"
                        >
                          <h5 className="tab-title">Room Details </h5>
                          <div className="entry-content">
                            <p>{description}</p>
                            <p className="room-notes-header">Note:</p>
                            <p className="room-notes-item">We do not include on-site DSTV in the rooms. However, if you have the DSTV Now app installed on your phone you can easily cast all DSTV channels to the smart TV. Of course, this assumes you have the app pre-installed on your phone and linked to your account</p>

                            
                            <div className="entry-gallery-img">
                              <figure className="entry-media-img">
                                <img src={ publicUrl + mainImage } alt={title} />
                              </figure>
                            </div>
                          </div>
                          <div className="room-specification">
                            <div className="row">
                              <div className="col-lg-6 col-md-12">
                                <div className="pricing-plan">
                                  <h4 className="specific-title">
                                    {" "}
                                    Pricing{" "}
                                  </h4>
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td>Weekdays (Sun-Thu): </td>
                                        <td className="big">{midweekRate}</td>
                                      </tr>
                                      <tr>
                                        <td>Weekends (Fri-Sat): </td>
                                        <td className="big">{weekendRate}</td>
                                      </tr>
                                      <tr>
                                        <td>High Season: </td>
                                        <td className="big">{highSeasonRate}</td>
                                      </tr>                                      
                                      <tr>
                                        <td>Minimum Number Of Days:</td>
                                        <td className="big">{minDays}</td>
                                      </tr>
                                      <tr>
		                                  <td>Maximum Number Of Days:</td>
		                                  <td className="big">{maxDays}</td>
		                                </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-12">
                                <div className="feature">
                                  <h4 className="specific-title"> Features </h4>
                                  <ul>
                                    { features.map((feature, i) => { return ( <li key={i}>{feature}</li>  ); })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {/* Sidebars Area - Add back when booking form works */}
            {/* <div className="col-lg-4">
              
              <div className="sidebar-wrap">
                <div className="widget booking-widget">
                  <h4 className="widget-title">
                    $160.00 <span>Night</span>
                  </h4>
                  <form>
                    <div className="input-wrap">
                      <input
                        type="text"
                        placeholder="Location"
                        id="f-location"
                      />
                      <i className="far fa-search" />
                    </div>
                    <div className="input-wrap">
                      <input
                        type="text"
                        placeholder="Arrive Date"
                        id="arrive-date"
                      />
                      <i className="far fa-calendar-alt" />
                    </div>
                    <div className="input-wrap">
                      <input
                        type="text"
                        placeholder="Depart Date"
                        id="depart-date"
                      />
                      <i className="far fa-calendar-alt" />
                    </div>
                    <div className="input-wrap">
                      <select name="guests" id="guests">
                        <option value="Guests">Guests</option>
                        <option value="Guests">Guests</option>
                        <option value="Guests">Guests</option>
                      </select>
                    </div>
                    <div className="input-wrap">
                      <button
                        type="submit"
                        className="btn filled-btn btn-block"
                      >
                        book now <i className="far fa-long-arrow-right" />
                      </button>
                    </div>
                  </form>
                </div>
                <div
                  className="widget banner-widget avson-go-top"
                  style={{
                    backgroundImage: "url(assets/img/blog/sidebar-banner.jpg)",
                  }}
                >
                  <h2>Booking Your Latest apartment</h2>
                  <p>
                    Lorem ipsum dolor sit amet conse ctetur adipisicing elit sed
                    do eiusmod tempor incididunt ut labore{" "}
                  </p>
                  <Link to="/room-details" className="btn filled-btn">
                    BOOK NOW <i className="far fa-long-arrow-right" />
                  </Link>
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </section>
    );
  }
}

export default RoomDetails;
