import React, { Component } from "react";
import { Link } from "react-router-dom";

class RoomSummaryCard extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let priceFrom = this.props.priceFrom ? this.props.priceFrom : "UNKNOWN";
    let title = this.props.title ? this.props.title : "UNKNOWN";
    let path = this.props.path ? this.props.path : "/room-grid";
    let cardImage = this.props.cardImage
      ? this.props.cardImage
      : "assets/img/rooms/room-card-image-370x250.jpg";
    let shortDescription = this.props.shortDescription
      ? this.props.shortDescription
      : "UNKNOWN";
    let sleeps = this.props.sleeps ? this.props.sleeps : "2";
    let imageAlt = this.props.imageAlt ? this.props.imageAlt : "room";

    return (
      <>
        <div className="single-room">
          <div className="room-thumb">
            <Link to={path}>
              <img
                src={
                  publicUrl + cardImage
                }
                alt={imageAlt}
              />
            </Link>
          </div>
          <div className="room-desc">
            <h4>
              <Link to={path}>{title}</Link>
            </h4>
            <p>
              {shortDescription}
            </p>
            <ul className="room-info list-inline">
              <li>
                <i className="far fa-bed" />
                Sleeps {sleeps}
              </li>
              <li>
                <i className="far fa-bath" />
                En-suite
              </li>
            </ul>
            <div className="room-price">
              <p>Book from {priceFrom} per night</p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RoomSummaryCard;
