import React, { useEffect } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import RoomDetails from "./section-components/room-details";
import Footer from "./global-components/footer";
import OtherRoomsList from "./section-components/other-rooms-list";
import AnalyticsPageViews from "./global-components/analytics-page-views";

const RoomDetailsLittleAddo = () => {
  useEffect(() => {
    AnalyticsPageViews(window.location.pathname, "Room-Details-Little-Addo");
  }, []);
  
  const sliders = [
    {
      path: "assets/img/rooms/littleaddo-suite/little-addo-slider-1920x1080-07.jpg",
      altText: "Pic 1",
    },
    {
      path: "assets/img/rooms/littleaddo-suite/little-addo-slider-1920x1080-01.jpg",
      altText: "Pic 1",
    },
    {
      path: "assets/img/rooms/littleaddo-suite/little-addo-slider-1920x1080-02.jpg",
      altText: "Pic 1",
    },
    {
      path: "assets/img/rooms/littleaddo-suite/little-addo-slider-1920x1080-03.jpg",
      altText: "Pic 1",
    },
    {
      path: "assets/img/rooms/littleaddo-suite/little-addo-slider-1920x1080-04.jpg",
      altText: "Pic 1",
    },
    {
      path: "assets/img/rooms/littleaddo-suite/little-addo-slider-1920x1080-05.jpg",
      altText: "Pic 1",
    },
    {
      path: "assets/img/rooms/littleaddo-suite/little-addo-slider-1920x1080-06.jpg",
      altText: "Pic 1",
    },
    {
      path: "assets/img/rooms/littleaddo-suite/little-addo-slider-1920x1080-08.jpg",
      altText: "Pic 1",
    }
  ];

  const features = [
    "En-suite bathroom with Shower",
    "King Size Bed",
    "Smart TV",
    "Netflix",
    "Google Chromecast",
    "Fridge",
    "Microwave",
    "Gas Hob",
    "Coffee/Tea Station",
  ];

  const description = `The Addo Elephant Park inspires the theme for our
        Little Addo Suite. It is a private en-suite unit that sleeps 2 people with the option of a 
        king-size bed or 2 single beds. There is a kitchenette with all the basics 
        you will need, such as a kettle, gas hob, microwave, fridge and toaster. 
        Other facilities include a smart TV with internet access and a Chromecast 
        device that can be connected to guests' smartphone to stream Netflix, 
        Youtube or the DStv app. The private en-suite bathroom is fitted with a shower. 
        The room faces onto a communal open terrace with tables, chairs and braai facilities. 
        Free wifi is available in all the rooms. Please note that all our rooms are non-smoking, 
        and regrettably no pets are allowed.`;

  return (
    <div>
      <Navbar />
      <PageHeader
        headertitle="Little Addo Suite"
        BigText="Little"
        Img="rooms/littleaddo-suite/littleaddo-header-1920x510.jpg"
      />
      <RoomDetails
        title="Little Addo Suite"
        priceFrom="R 650.00"
        mainImage="assets/img/rooms/littleaddo-suite/littleaddo-main-image-1920x1440.jpg"
        sliderImages={sliders}
        sleeps="2"
        size="26 sq m"
        description={description}
        midweekRate="R 650.00"
        weekendRate="R 700.00"
        highSeasonRate="R 750.00"
        minDays="2"
        features={features}
      />
      <OtherRoomsList
        showAddo={true}
        showLighthouse={false}
        showBeach={true}
        showLittleAddo={false}
        showFamily={true}
      />
      <Footer />
    </div>
  );
};

export default RoomDetailsLittleAddo;
