import React, { Component } from "react";

class WhyChooseUs extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <section className="wcu-section section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              {/* Section Title */}
              <div className="section-title">
                <span className="title-top">Why Choose Us</span>
                <h1>
                  Enjoy an affordable family holiday with everything you need
                </h1>
              </div>
              <div
                className="feature-accordion accordion"
                id="featureAccordion"
              >
                <div className="card">
                  <div className="card-header">
                    <button
                      type="button"
                      data-toggle="collapse"
                      data-target="#featureTwo"
                    >
                      Spacious En-suite Rooms
                      <span className="open-icon">
                        <i className="far fa-eye-slash" />
                      </span>
                      <span className="close-icon">
                        <i className="far fa-eye" />
                      </span>
                    </button>
                  </div>
                  <div
                    id="featureTwo"
                    className="collapse"
                    data-parent="#featureAccordion"
                  >
                    <div className="card-body">
                      All our units are fully self-contained with en-suite bathrooms, each with a shower. Each unit has its own entrance and an 
                      allocated parking space inside the property, so you do not have to park your car on the street. At the end of the day you 
                      can sleep comfortably in the king-size bed.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header ">
                    <button
                      type="button"
                      className="active-accordion"
                      data-toggle="collapse"
                      data-target="#featureOne"
                    >
                      Self-catering
                      <span className="open-icon">
                        <i className="far fa-eye-slash" />
                      </span>
                      <span className="close-icon">
                        <i className="far fa-eye" />
                      </span>
                    </button>
                  </div>
                  <div
                    id="featureOne"
                    className="collapse show"
                    data-parent="#featureAccordion"
                  >
                    <div className="card-body">
                      The units are fully self-catering and provide all the items you will need, including a two-plate compact oven, microwave, 
                      bar fridge, kettle, crockery, cutlery, pots and pans. Plus of course a braai and a kettle braai for the braai-masters out there.
                    </div>
                  </div>
                </div>
                
                <div className="card">
                  <div className="card-header">
                    <button
                      type="button"
                      data-toggle="collapse"
                      data-target="#featureThree"
                    >
                      Convenient Location Close to Amenities
                      <span className="open-icon">
                        <i className="far fa-eye-slash" />
                      </span>
                      <span className="close-icon">
                        <i className="far fa-eye" />
                      </span>
                    </button>
                  </div>
                  <div
                    id="featureThree"
                    className="collapse"
                    data-parent="#featureAccordion"
                  >
                    <div className="card-body">
                      We are located close to a number of amenities, such as the beach, the Nelson Mandela University, Humewood Golf Club and restaurants. 
                      The ocean is only 600 m from us and the nearest swimming beach, Pollock Beach, is about 1 km away. 
                      We are a mere 6.7 km (about 10 minutes'  drive) from PE International Airport. 

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="feature-accordion-img text-right">
                <img
                  src={
                    publicUrl +
                    "assets/img/home-why-choose-us/why-us-540x684.jpg"
                  }
                  alt="Why Choose Us"
                />
                <div className="degin-shape">
                  <div className="shape-one">
                    <img
                      src={
                        publicUrl +
                        "assets/img/home-why-choose-us/why-us-shape-triangle.png"
                      }
                      alt="Shape"
                    />
                  </div>
                  <div className="shape-two">
                    <img
                      src={
                        publicUrl +
                        "assets/img/home-why-choose-us/why-us-shape-circle.png"
                      }
                      alt="Shape"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default WhyChooseUs;
