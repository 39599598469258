import React, { useEffect } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import Footer from "./global-components/footer";
import RoomDetails from "./section-components/room-details";
import OtherRoomsList from "./section-components/other-rooms-list";
import AnalyticsPageViews from "./global-components/analytics-page-views";

const RoomDetailsFamily = () => {
  useEffect(() => {
    AnalyticsPageViews(window.location.pathname, "Room-Details-Family");
  }, []);
  
  const sliders = [
    {
      path: "assets/img/rooms/family-unit/family-slider-1920x1080-01.jpg",
      altText: "Addo Suite Bed",
    },    
    {
      path: "assets/img/rooms/family-unit/family-slider-1920x1080-02.jpg",
      altText: "Addo Suite Bathroom",
    },
    {
      path: "assets/img/rooms/family-unit/family-slider-1920x1080-03.jpg",
      altText: "Addo Suite Bed from Angle",
    },    
    {
      path: "assets/img/rooms/family-unit/family-slider-1920x1080-04.jpg",
      altText: "Addo Suite Bathroom Counter",
    },
    {
      path: "assets/img/rooms/family-unit/family-slider-1920x1080-05.jpg",
      altText: "Addo Suite Wide View",
    },   
    {
      path: "assets/img/rooms/family-unit/family-slider-1920x1080-06.jpg",
      altText: "Little Addo 3-bed Setup",
    },
    {
      path: "assets/img/rooms/family-unit/family-slider-1920x1080-07.jpg",
      altText: "Little Addo 2-bed Setup",
    },    
    {
      path: "assets/img/rooms/family-unit/family-slider-1920x1080-08.jpg",
      altText: "Little Addo king-bed Setup",
    },
    {
      path: "assets/img/rooms/family-unit/family-slider-1920x1080-09.jpg",
      altText: "Little Addo Wide View",
    },        

  ];

  const features = [
    "En-suite bathroom with Shower",
    "King Size Bed",
    "Smart TV",
    "Netflix",
    "Google Chromecast",
    "Fridge",
    "Microwave",
    "Gas Hob",
    "Coffee/Tea Station",
  ];

  const description = `The Addo and Little Addo Suites can be joined as a
  family unit that sleeps 4 (or potentially 5, at an extra fee). Both rooms have en-suite
  bathrooms. The Addo Elephant Park inspires the elephant theme for the two rooms. 
  The main room has a kitchenette with all the basics 
  you will need, such as a kettle, gas hob, microwave, fridge and toaster. 
  Other facilities include a smart TV in both rooms with internet access and a Chromecast 
  device that can be connected to guests' smartphone to stream Netflix, 
  Youtube or the DStv app. The private en-suite bathrooms are fitted with showers. 
  Both rooms face onto a communal open terrace with tables, chairs and braai facilities. 
  Free wifi is available in all the rooms. Please note that all our rooms are non-smoking, 
  and regrettably no pets are allowed.`;

  return (
    <div>
      <Navbar />
      <PageHeader
        headertitle="Family Unit"
        BigText="Family"
        Img="rooms/family-unit/family-header-1920x510.jpg"
      />
      <RoomDetails
        title="Family Unit"
        priceFrom="R 1350.00"
        mainImage="assets/img/rooms/family-unit/family-main-image-1920x1440.jpg"
        sliderImages={sliders}
        sleeps="4 (or 5)"
        size="61 sq m"
        description={description}
        midweekRate="R 1350.00"
        weekendRate="R 1450.00"
        highSeasonRate="R 1550.00"
        minDays="2"
        features={features}
      />
      <OtherRoomsList
        showAddo={true}
        showLighthouse={false}
        showBeach={true}
        showLittleAddo={true}
        showFamily={false}
      />
      <Footer />
    </div>
  );
};

export default RoomDetailsFamily;
