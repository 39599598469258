import React, { useEffect } from "react";
import Navbar from "./global-components/navbar";
import SliderBar from "./section-components/slider-bar";
import Welcome from "./section-components/welcome";
import CallToAction from "./section-components/call-to-action";
import WhyChooseUs from "./section-components/why-choose-us";
import ClientFeedback from "./section-components/client-feedback";
import Contact from "./section-components/contact";
import Footer from "./global-components/footer";
import AnalyticsPageViews from "./global-components/analytics-page-views";

const Home = () => {
  useEffect(() => {
    AnalyticsPageViews(window.location.pathname, "Home");
  }, []);
  
  return (
    <div>
      <Navbar />
      <SliderBar />
      <Welcome />
      <ClientFeedback />
      <WhyChooseUs />
      <CallToAction />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
