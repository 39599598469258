import React, { Component } from "react";

class ClientFeedback extends Component {
  render() {
    return (
      <section className="feedback-section section-padding">
        <div className="container">
          {/* Section Title */}
          <div className="section-title text-center">
            <h1>Feedback from previous guests </h1>
          </div>
          <div className="feadback-slide row" id="feedbackSlideActive">
            <div className="col-lg-6">
              <div className="single-feedback-box">
                <h3>I highly recommend.</h3>
                <p>
                  Cleanliness, spaciousness and the bed is very comfortable. It's
                  a home away from home.
                </p>
                <h5 className="feedback-author">Zimkhitha (March 2022)</h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-feedback-box">
                <h3>Lekker gekuier in 'n rustige omgewing</h3>
                <p>
                  Alles en nog meer wat 'n reisiger verlang. Dit is ook naby
                  genoeg aan die see en uitstekende waarde vir geld! Die
                  eienaars het ons ook kom groet voor ons vertrek het. Julle is
                  sterre!
                </p>
                <h5 className="feedback-author">Pedro (January 2022)</h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-feedback-box">
                <h3>Gerieflike verblyf in 'n rustige omgewing</h3>
                <p>
                  Gasvryheid was uitnemend en die akkommodasie was regtig
                  gerieflik. Dit is die fynere besonderhede wat voorsien word
                  deur die eienaars wat uitstaande is. Baie Dankie Rosalie vir
                  die omgee vir jou gaste.
                </p>
                <h5 className="feedback-author">Paul (November 2021)</h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-feedback-box">
                <h3>I loved everything about the place</h3>
                <p>
                  Everything we needed was there. The extra mile they went in
                  terms of the self catering was excellent!
                </p>
                <h5 className="feedback-author">Ncediswa (February 2022)</h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-feedback-box">
                <h3>Akkomodasie vir Ironman</h3>
                <p>
                Ons het lekker gebly in skoon, netjiese, ruim woonstel. 
                Als was reg toe ons inboek en ontvangs vriendelik. Dadelik tuis gevoel. 
                Sal vir seker weer daar bly! Baie dankie!
                </p>
                <h5 className="feedback-author">Elise (April 2022)</h5>
              </div>
            </div>            
            <div className="col-lg-6">
              <div className="single-feedback-box">
                <h3>Best in a long time</h3>
                <p>
                  Like the hospitality as well as the walking distance from the
                  sea.
                </p>
                <h5 className="feedback-author">Bernard (April 2022)</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ClientFeedback;
