import React, { Component } from "react";
import Slider from "./slider";

class SliderBar extends Component {
  render() {
    return (
      <section className="hero-section slider-two" id="secondSlider">
        <Slider
          heading="Relax in Summerstrand"
          sliderImage="assets/img/home-slider/home-slider-1-relax-1920x849.jpg"
          shortDescription="Sometimes the best thing you can do on a holiday is to chill
          and forget about the rest of the world. Grab a beer at one
          of the many family-friendly restaurants lining Marine drive
          and take in the scenery."
        />
        <Slider
          heading="Walk to the Beach"
          sliderImage="assets/img/home-slider/home-slider-2-beach-1920x849.jpg"
          shortDescription="Nothing beats a casual stroll down the beach. We're 600m 
          from the beach and boardwalk where you can take a stroll to appreciate the
          African sunset. Multiple blue-flagh beaches are within 2 km of the guest house."
        />
        <Slider
          heading="Braai some Fish with Friends"
          sliderImage="assets/img/home-slider/home-slider-3-braai-1920x849.jpg"
          shortDescription="The harbour is a few minutes' drive from our guest house, where 
          you'll find a fresh selection of fish and calamari, caught daily. And if you've 
          never cooked a fish on the braai, we'll give you some pointers. It's not to be missed."
        />
        {/* <Slider
          heading="Hike the Coast"
          sliderImage="assets/img/home-slider/home-slider-4-hike-1920x849.jpg"
          shortDescription="There are many short and longer hikes in our area. The easiest is the Cafe Recife reserve 
          that's just around the corned from us, but that's certainly not the only option. 
          The hike between Schoenmakerskop and Sardinia Bay is not to be missed. It'll take a few hours, but the views are breathtaking"
        />
        <Slider
          heading="Bike to Schoenies"
          sliderImage="assets/img/home-slider/home-slider-5-bike-1920x849.jpg"
          shortDescription="The ride from our front-door to Schoenmakerskop is probably
          one of the most beautiful cycling routes in the country.
          Over a distance of 20km, the road hugs the beautify Eastern
          Cape coastline, past Cape Recife and many fishing spots
          along the way. If you're a cyclist, this route cannot be
          missed"
        /> */}
        {/* <Slider
          heading="Watch the Whales"
          sliderImage="assets/img/home-slider/home-slider-6-whales-1920x849.jpg"
          shortDescription="If you're visiting us between July and October, then you should absolutely
          book a Whale watching trip with one of the operators at the harbour. Algoa Bay plays host to 
          Southern Right and Humpback whale. In fact, we even see Orcas from time to time."
        /> */}
        <Slider
          heading="Become an Ironman"
          sliderImage="assets/img/home-slider/home-slider-7-ironman-1920x849.jpg"
          shortDescription="Our guest house is situated right on the Ironman route,
          making it the perfect home base if you're competing. Best of
          all, while you suffer out on the route, your supporters will
          be able to relax and cheer you on from time to time."
        />
        {/* <Slider
          heading="Meet the Big 5 in Addo"
          sliderImage="assets/img/home-slider/home-slider-8-addo-1920x849.jpg"
          shortDescription="The entrance to the Addo Elephant Park is a mere 30 minutes'
          drive from our guest house - Perfect for a day trip. You are absolutely guaranteed
          to see massive herds of Elephants in the park, but if you wear your lucky socks,
          you can also glimpse a lot more."
        /> */}
      </section>
    );
  }
}

export default SliderBar;
