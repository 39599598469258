import React, { useEffect } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
// import ContactInfo from "./section-components/contact-info";
// import ContactMap from "./section-components/contact-map";
import Contact from "./section-components/contact";
// import ContactForm from "./section-components/contact-form";
import Footer from "./global-components/footer";
import AnalyticsPageViews from "./global-components/analytics-page-views";

const ContactPage = () => {
  useEffect(() => {
    AnalyticsPageViews(window.location.pathname, "Contact");
  }, []);
  
  return (
    <div>
      <Navbar />
      <PageHeader
        headertitle="Contact"
        BigText="Contact"
        Img="contact//contact-page-header-1920x510.jpg"
      />
      <Contact />
      {/* <ContactInfo />
      <ContactMap /> */}
      {/* <ContactForm /> */}
      <Footer />
    </div>
  );
};

export default ContactPage;
